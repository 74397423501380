import React from 'react';




import './index.css'; // Import your CSS file

const HeroSection = () => {
  
  return (
   
    <div className="hero-container flex flex-col md:flex-row h-auto md:h-screen content-center " style={{
      backgroundImage: 'url("background.svg")',
      backgroundSize: 'cover'
      

      
    }}>
        
      <div className="text-container flex-grow md:w-1/2 md:pr-8 md:flex md:flex-col md:justify-left">
        <p className="text-[#464646] mt-12 md:text-lg pt-12">
          Hello, It’s me SAJAN
        </p>
        <h1 className="text-[#0C0C0C] text-4xl md:text-6xl font-bold leading-tight py-4">
          UI/UX Designer
        </h1>
        <p className="text-[#464646] md:text-lg">
          I'm passionate about crafting digital experiences that seamlessly blend beauty with functionality. As a dedicated <span className='font-bold'>UI/UX Designer</span> and seasoned Project Manager, I specialize in the art of transforming ideas into visually stunning, user-centric solutions.
        </p>
        <div className="mt-6">
          <button className="bg-[#00A651] font-semibold text-white px-4 py-2 rounded-md hover:bg-[#01803F]">
            <a href='https://wa.me/9860465233'>Hire me</a>
          </button>
          <button className="ml-4 text-[#464646] font-semibold hover:text-[#01803F] "><a href='https://www.behance.net/sazansngakhus' >View Projects</a></button>
        </div>
      </div>
      <div className="image-container md:w-1/2 md:mt-auto">
        <img src="HeroImage.png" alt="Hero" className="w-full h-full object-cover object-bottom" />
      </div>
    </div>
    
  );
};

export default HeroSection;
