import React from 'react';
import { FaBehance, FaLinkedin,FaWhatsapp,FaGithub } from 'react-icons/fa';

const End = () => {
  // const currentYear = new Date().getFullYear();
  return (
    <div className="bg-[#f7f7f7] text-[#464646] ">
      <div className="container mx-auto flex flex-col items-center py-8 ">
        {/* Social Icons */}
        <div className="flex items-center space-x-4 mb-4 pb-4">
        <a href="https://github.com/sazansingh10" target="_blank" rel="noopener noreferrer" className="text-behance">
            <FaGithub size={24} />
          </a>
        <a href="https://wa.me/9860465233" target="_blank" rel="noopener noreferrer" className="text-green-700">
            <FaWhatsapp size={24} />
          </a>
          <a href="https://www.behance.net/sazansngakhus" target="_blank" rel="noopener noreferrer "className="text-behance">
            <FaBehance size={24} />
          </a>
          <a href="https://www.linkedin.com/in/sazan-singh-ngakhusi-8243611a1/" target="_blank" rel="noopener noreferrer" className="text-linkedin">
            <FaLinkedin size={24} />
          </a>
        </div>

        {/* Creative Content */}
        <div className="text-center px-6 md:px-20 tracking-tight">
          <p className="text-[24px] font-bold  text-[#0C0C0C]">Like what you see?</p>
          <p className='text-[16px] font-regular py-1'>Let's start brewing something amazing together.</p>
        </div>

        {/* Contact Information */}
        <div className="my-4 md:flex md:gap-12">
          <p className="mb-1">
            <strong>Email:</strong> <a href="mailto:sazansingh10@gmail.com">sazansingh10@gmail.com</a> 
          </p>
          <p>
            <strong>Contact:</strong> <a href="tel:+11234567890" className="fold-semibold">+977 9860465233</a> 
          </p>
        </div>
        <hr className="my-1 border-t border-gray-400 w-full" />
        <div className="mt-4 text-[14px] tracking-tight">
          <p>Design & Developed by: <span className='font-bold'>mr.sazansingh10</span></p>
          {/* <p>&copy; {currentYear} <span className="font-bold">@Mr.sazansingh10.</span>  All Rights Reserved.</p> */}
        </div>
      </div>
    </div>
  );
};

export default End;
