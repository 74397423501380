import React, { useState, useEffect } from 'react';
import { FaBehance, FaLinkedin } from 'react-icons/fa';
import './index.css'; 

const Nav = () => {
  const [scrollDirection, setScrollDirection] = useState('down');
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;

      if (currentScrollPos > prevScrollPos) {
        setScrollDirection('down');
      } else {
        setScrollDirection('up');
      }

      setPrevScrollPos(currentScrollPos);

      if (currentScrollPos < 100) {
        setIsVisible(true);
      } else {
        setIsVisible(currentScrollPos < prevScrollPos || currentScrollPos < 100);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);

  return (
    <nav className={`navbar ${isVisible ? 'nav-visible' : 'nav-hidden'} px-2 md:px-20 `}>
      {/* Logo and Just Nav */}
      <div className="flex items-center">
        <img src="logo.png" alt="Logo" className="h-10 w-auto mr-2" />
      </div>

      {/* Icons on the right */}
      <div className="flex items-center space-x-4 mt-4 md:mt-0 ">
        <a href="https://www.behance.net/sazansngakhus" target="_blank" rel="noopener noreferrer" className="text-behance">
          <FaBehance size={24} />
        </a>
        <a href="https://www.linkedin.com/in/sazan-singh-ngakhusi-8243611a1/" target="_blank" rel="noopener noreferrer" className="text-linkedin">
          <FaLinkedin size={24} />
        </a>
      </div>
    </nav>
  );
};

export default Nav;