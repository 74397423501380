import React from 'react';
import './index.css'; // Create a separate CSS file for your styles

const Tech = () => {
  const Frontend = [
    { name: 'HTML', imageUrl: 'html.png' },
    { name: 'JavaScript', imageUrl: 'js.png' },
    { name: 'Tailwind CSS', imageUrl: 'css.png' },
    { name: 'Reactjs', imageUrl: 'Reactjs1.png' },
    // Add more technologies as needed
  ];

  const Design = [
    { name: 'Photoshop', imageUrl: 'ps.png' },
    { name: 'Illustrator', imageUrl: 'ai.png' },
    { name: 'Figma', imageUrl: 'figma.png' },
    { name: 'Canva', imageUrl: 'canva.png' },
    // Add more technologies as needed
  ];

  const Other = [
    { name: 'Github', imageUrl: 'github.png' },
    { name: 'Flutter', imageUrl: 'Flutter.png' },
    { name: 'Netlify', imageUrl: 'Netlify.png' },
    { name: 'Microsoft Office', imageUrl: 'mo.png' },
    { name: 'Slack', imageUrl: 'slack.png' },
    { name: 'Jira', imageUrl: 'Jira.png' },
    // Add more technologies as needed
  ];

  return (
    <div className="bg-[#00A651] py-8 px-8 md:px-20">
      <div className="container flex flex-col ">
        <h2 className="text-2xl font-semibold mb-12 text-[#FDFDFD]">Technologies I'm Familiar With</h2>

        <div className='flex-col md:flex md:flex-row'>
          <div className="flex-1 tech-category">
            <p className="text-white cursor-pointer">
              Frontend Technologies
            </p>
            <div className="pt-3 grid grid-cols-4 md:grid-cols-6 lg:grid-cols gap-1">
              {Frontend.map((tech, index) => (
                <div key={index} className="relative overflow-hidden grayscale-0 hover:grayscale transition-all">
                  <img
                    src={`${process.env.PUBLIC_URL}/tech/${tech.imageUrl}`}
                    alt={tech.name}
                    className="w-12 h-12 object-fit rounded-md"
                  />
                  <p className="text-white mt-2 tech-name">{tech.name}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="flex-1 tech-category mt-2">
            <p className="text-white cursor-pointer">
              Designing Software
            </p>
            <div className="pt-3 grid grid-cols-4 md:grid-cols-6 lg:grid-cols gap-1">
              {Design.map((tech, index) => (
                <div key={index} className="relative overflow-hidden grayscale-0 hover:grayscale transition-all">
                  <img
                    src={`${process.env.PUBLIC_URL}/tech/${tech.imageUrl}`}
                    alt={tech.name}
                    className="w-12 h-12 object-fit rounded-md"
                  />
                  <p className="text-white mt-2 tech-name">{tech.name}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="tech-category mt-2">
          <p className="text-white cursor-pointer">
            Other Tools
          </p>
          <div className="pt-3 grid grid-cols-4 md:grid-cols-12 lg:grid-cols gap-2">
            {Other.map((tech, index) => (
              <div key={index} className="relative overflow-hidden grayscale-0 hover:grayscale transition-all">
                <img
                  src={`${process.env.PUBLIC_URL}/tech/${tech.imageUrl}`}
                  alt={tech.name}
                  className="w-12 h-12 object-fit rounded-md"
                />
                <p className="text-white mt-2 tech-name">{tech.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tech;
