import React from "react";
import Nav from "./Nav";




function App() {
  return (
    <div className="App">
      <div><Nav/>
     
   
  
    </div>
    
    
   
      
    </div>
  );
}

export default App;
